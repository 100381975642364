ul.rocketSiloChecklist {
  list-style: none;
  li {
    margin-bottom: 5px;

    span {
      font-weight: bold;
    }
    span.ready {
      color: #0f0;
    }
    span.notReady{
      color: #f00;
    }
  }
}