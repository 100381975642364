div.nameAndImageHeader {
    display: flex;
    align-items: center;
    background-color: #444;
    min-width: 200px;
    margin-bottom: 10px;

    div.icon{
        background: #999 no-repeat 3px 3px;
        height: 40px;
        width: 40px;
        box-sizing: border-box;
        margin: 2px;
        border: 1px solid #ccc;
        border-right: 2px #2a2a2a;
        border-bottom: 2px #2a2a2a;
    }
    div.largeIcon{
        height: 60px;
        width: 60px;
        background: #999 no-repeat 13px 13px;
    }
    div.name{
        padding: 2px 10px;
        font-weight: bold;
    }
}
