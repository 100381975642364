div.rocketSiloContainer {
    background: url(../../images/falconheavy.png) no-repeat 50% 200px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    div.rocketSiloBuildSilo {
        height: 400px;
        width: 100%;

        >div {
            padding: 10px;
            width: 300px;
        }
    }

    div.rocketSiloRocket {
        height: 400px;
        width: 40%;
    }

    div.rocketSiloChecklist {
        height: 400px;
        width: 40%;
    }

    div.rocketSiloOps {
        height: 400px;
        width: 40%;
    }

    div.rocketSiloFuel {
        height: 400px;
        width: 40%;
    }
}