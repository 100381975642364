html {
    height: 100%;
}
body{
    background-color: #222;
    color: white;
}

div.defaultContainer {margin-left: 300px; margin-top: 70px;}

div.simpleDivider{
    background-color: #111;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

div.furnace, div.powerplant, div.assembler{
    background-color: #333;
    padding: 10px;
    margin-bottom: 5px;
}

.react-tooltip-lite {
    background: #000;
    color: white;
}

.react-tooltip-lite-arrow {
    border-color: #000;
}