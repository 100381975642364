div.productionContainer {
    display: flex;

    div.productionMachines {
        width: 70%;
        ul.machineList {
            list-style: none;
            padding:0;
            margin: 0 0 20px;
            display: flex;
            flex-wrap:wrap;
            align-content: flex-start;

            li{
                box-sizing: border-box;
                position: relative;
                background-color: #444;
                padding: 5px;
                width: 90px;
                margin-right: 2px;
                margin-bottom: 2px;
                div.machineName{
                    font-weight: bold;
                }

                div.machineImage {
                    height: 80px;
                    width: 80px;
                    background: #666 no-repeat 23px 23px;
                    box-sizing: border-box;
                    padding-left: 18px;
                    padding-top: 40px;
                }
            }
        }
    }

    div.productionSidePanel {
        min-width: 200px;
        max-width: 400px;
        background-color: #333;

    }
}

div.itembuttons{
    display: flex;
    flex-wrap:wrap;
    align-content: flex-start;
}