div.researchContainer{
    display: flex;
}

div.researchListContainer{
    width: 500px;

    ul.researchList{
        list-style: none;
        margin: 0;
        padding: 0;
        li{
            background: #333;
            margin-bottom: 2px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            div.researchName {
                font-weight: bold;
                padding: 3px 10px;

            }
        }
        li:hover{
            background: #444;
        }
        li.notLearnable{
            opacity: 0.4;
        }
        li.hasLearned {
            opacity: 0.4;
            background-color: #060;
        }
        li.selected {
            background-color: #162f96;
        }
    }
}

div.researchSelectionPanel{
    margin-left: 10px;
    width: 400px;
    background-color: #333;

    div.researchUnlocksContainer, div.unlocksItemsContainer, div.researchRequiredContainer {
        margin-top: 10px;
    }

    div.unlocksItems{
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
}
