div.researchItem{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    div.name {
        padding: 2px 10px;
    }
    margin-bottom: 3px;
    background-color: #555;
}

div.researchItemGreen {
    background-color: #050;
}

div.researchItemRed {
    background-color: #500;
}