div.itemIcon {
    height: 40px;
    width: 40px;
    box-sizing: border-box;
    text-align: right;
    padding-top: 18px;
    padding-right: 3px;
    margin: 2px;
    border: 1px solid #ccc;
    border-right: 2px #2a2a2a;
    border-bottom: 2px #2a2a2a;
    font-weight: bold;
    color: #fff;
    background: #999 no-repeat 3px 3px;
    text-shadow: 1px 1px 1px #000, -1px -1px 1px #000, 1px -1px 1px #000, -1px 1px 1px #000;
}

div.itemIcon-trans {
    background: transparent no-repeat 3px 3px;
    border: none;
}


div.itemIconSmall{
    background: #999 no-repeat 0 0;
    background-size: contain;
    height: 24px;
    width: 24px;
    padding-top: 7px;
    padding-right: 2px;
    font-size: 12px;
}

div.notCraftable{
    background-color: #999;
    opacity: 0.4;
}

div.itemIconClickable {
    cursor: pointer;
}

div.itemSelected{
    background-color: #0a7fb5;
}

div.itemGreen{
    background-color: #080;
}

div.itemRed{
    background-color: #800;
}
