div.playerCraftingBar{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 280px;
    height: 100px;
    padding: 10px;
    margin: 10px;
    background-color: #222;
    box-sizing: border-box;

    div.playerCraftingBarInner{
        display: flex;
    }

    div.progressbar {
        margin-top: 5px;
    }
}