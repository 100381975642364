div.mapcontainer {
    display: flex;
}

div.resourceMapGrid{
    height: 400px;
    width: 400px;
    border: solid 1px #666;
    position: relative;
    background: url(./../../images/map.jpeg);

    >div {
        height: 38px;
        width: 38px;
        border: solid 1px #666;
        position: absolute;
        background: #000 no-repeat 2px 2px;
        opacity: 0.7;

        div.gridCellMine{

            position: absolute;
            top: 2px;
            left: 2px;
            height: 36px;
            width: 36px;

            div.progressbar {
                position: absolute;
                bottom: 2px;
                left: 2px;
                width: 30px;
            }

        }
    }

    div.explored {
        background-color: transparent;
        opacity: 1;
    }

    div.exploring {
        background: rgba(0,0,0,0.7) url(./../../images/search.png) no-repeat 2px 2px;
        opacity: 1;
    }

    div:hover {
        border: solid 1px #aaa;
    }

    div.selected {
        border: solid 1px #00f;
    }


}

div.mapSelectionPanel{
    margin-left: 10px;

    width: 300px;
    min-height: 300px;
    background-color: #333;
    h2 {
        background-color: #444;
        font-size: 20px;
        text-align: center;
        padding: 3px 15px;
        margin: 0;
    }

    div.exploring{
        margin: 5px 0;

        div.progressbar {
            margin-top: 5px;
        }
    }

    div.sectorContents{
        padding: 5px;
        div.resourceNameAndIcon{
            display: flex;
            align-items: center;
            background-color: #444;
            min-width: 200px;
            div.resourceName{
                padding: 2px 10px;
                font-weight: bold;
            }
            div.resourceIcon{
                height: 38px;
                width: 38px;
                background: no-repeat 3px 3px;
            }

        }
    }
}