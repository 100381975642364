div.progressbar{
    box-sizing: border-box;
    width: 100%;
    height: 10px;
    border: solid 1px #666;
    background-color: #333;

    div.bar {
        background-color: #080;
        height: 8px;
    }
}

div.progressbar_mini {
    height: 5px;
    div.bar {
        height: 3px;
    }
}
