div.handMiningButton {
  background: #222 url('../../images/pick.png') no-repeat 5px 5px;
  padding: 15px 15px 15px 45px;
  cursor: pointer;
  box-sizing: border-box;
  margin: 5px;
  &:hover {
    background-color: #333;
  }
}

div.handMiningButton_disabled{
  cursor: default;
  opacity: 0.4;
}